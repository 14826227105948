import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.growers().forEach((grower) => {
      const textarea = grower.querySelector('textarea');
      textarea.addEventListener('input', () => {
        this.updateReplicatedValue(grower, textarea);
      });
      this.updateReplicatedValue(grower, textarea);
    });
  }

  growers() {
    return Array.from(this.element.querySelectorAll('.grow-wrap'));
  }

  onInputWrapperClick() {
    this.inputTarget.focus();
  }

  updateReplicatedValue(grower, textarea) {
    // eslint-disable-next-line no-param-reassign
    grower.dataset.replicatedValue = textarea.value;
  }
}
