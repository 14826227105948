import { Controller } from '@hotwired/stimulus';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  TimeScale,
  TimeSeriesScale,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';

export default class extends Controller {
  static targets = ['chart'];

  connect() {
    const submissionData = JSON.parse(this.chartTarget.dataset.submissionData);
    const dates = Object.keys(submissionData);
    const cumulativeCounts = Object.values(submissionData);

    // Register necessary components with Chart.js
    Chart.register(
      LineController,
      LineElement,
      PointElement,
      LinearScale,
      Title,
      CategoryScale,
      TimeScale,
      TimeSeriesScale,
      Tooltip,
    );

    this.chart = new Chart(this.chartTarget, {
      type: 'line',
      data: {
        labels: dates,
        datasets: [{
          data: cumulativeCounts,
          fill: true,
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderWidth: 1,
          tension: 0.4,
          pointRadius: 0,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'dd.MM.yyyy',
              },
            },
            offset: false,
            grid: {
              display: false,
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              autoSkip: true,
              maxTicksLimit: 3,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: 'nearest',
            intersect: false,
            callbacks: {
              title(tooltipItems) {
                const date = tooltipItems[0].parsed.x; // This should be the timestamp
                return DateTime.fromMillis(date).toFormat('dd.MM.yyyy');
              },
              label(tooltipItem) {
                return tooltipItem.raw;
              },
            },
          },
        },
      },
    });
  }
}
