import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['content'];

  static values = {
    easilyClosable: Boolean,
  };

  connect() {
    window.addEventListener('keydown', this.onEscape.bind(this));

    useClickOutside(this, { element: this.contentTarget });
  }

  disconnect() {
    window.removeEventListener('keydown', this.onEscape);
  }

  onEscape(event) {
    if (!this.easilyClosableValue) return;
    if (event.code !== 'Escape') return;

    event.preventDefault();
    this.remove();
  }

  clickOutside(event) {
    if (!this.easilyClosableValue) return;
    event.preventDefault();
    if (this.element === event.target || this.element.contains(event.target)) this.remove();
  }

  remove() {
    this.element.remove();
  }
}
