import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { riskAssessmentsUrl: String };

  connect() {
    this.startTimer();
  }

  startTimer() {
    const countdownElement = this.element.querySelector('#countdown');

    let count = 5;

    this.timer = setInterval(() => {
      // eslint-disable-next-line no-plusplus
      count--;
      if (count < 0) {
        clearInterval(this.timer);
        window.location.href = this.riskAssessmentsUrlValue;
      } else {
        countdownElement.textContent = count;
      }
    }, 1000);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  disconnect() {
    this.stopTimer();
  }
}
