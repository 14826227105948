import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['surveyRadioButton'];

  static classes = ['checked', 'unchecked'];

  connect() {
    this.surveyRadioButtonTargets.forEach((radioButton) => {
      radioButton.addEventListener('click', this.toggleRadioButton.bind(this));
    });

    this.applyInitialCheckedState();
  }

  toggleRadioButton(event) {
    const radioButton = event.target;

    if (radioButton.dataset.wasChecked === 'true') {
      radioButton.checked = false;
      this.uncheck(radioButton);
    } else {
      this.check(radioButton);
    }
    radioButton.dispatchEvent(new CustomEvent('radioClick', { bubbles: true }));

    this.surveyRadioButtonTargets.forEach((sibling) => {
      if (sibling === radioButton) return;
      this.uncheck(sibling);
    });
  }

  applyInitialCheckedState() {
    this.surveyRadioButtonTargets.forEach((radioButton) => {
      if (radioButton.checked) this.check(radioButton);
    });
  }

  keypressEnterLabel(event) {
    event.preventDefault();
    event.target.click();
  }

  check(radioButton) {
    radioButton.dataset.wasChecked = 'true';
    radioButton.labels.forEach((label) => {
      label.classList.remove(...this.uncheckedClasses);
      label.classList.add(...this.checkedClasses);
    });
  }

  uncheck(radioButton) {
    radioButton.dataset.wasChecked = 'false';
    radioButton.labels.forEach((label) => {
      label.classList.remove(...this.checkedClasses);
      label.classList.add(...this.uncheckedClasses);
    });
  }
}
