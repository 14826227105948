import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['surveyRadioButton', 'surveyCheckbox'];

  static classes = ['checkedInput', 'uncheckedInput', 'checkedOption', 'uncheckedOption'];

  connect() {
    this.surveyRadioButtonTargets.forEach((radioButton) => {
      radioButton.addEventListener('click', this.toggleRadioButton.bind(this));
    });

    this.surveyCheckboxTargets.forEach((checkbox) => {
      checkbox.addEventListener('click', this.toggleCheckbox.bind(this));
    });

    this.applyInitialCheckedState();
  }

  toggleRadioButton(event) {
    const radioButton = event.target;

    if (radioButton.dataset.wasChecked === 'true') {
      radioButton.checked = false;
      this.uncheck(radioButton);
    } else {
      this.check(radioButton);
    }
    radioButton.dispatchEvent(new CustomEvent('radioClick', { bubbles: true }));

    this.surveyRadioButtonTargets.forEach((sibling) => {
      if (sibling === radioButton) return;
      this.uncheck(sibling);
    });
  }

  toggleCheckbox(event) {
    const checkbox = event.target;

    if (checkbox.checked === true) {
      this.check(checkbox);
    } else {
      this.uncheck(checkbox);
    }
  }

  applyInitialCheckedState() {
    this.surveyRadioButtonTargets.forEach((radioButton) => {
      if (radioButton.checked) this.check(radioButton);
    });

    this.surveyCheckboxTargets.forEach((checkbox) => {
      if (checkbox.checked) this.check(checkbox);
    });
  }

  keypressEnterLabel(event) {
    event.preventDefault();
    event.target.click();
  }

  check(input) {
    if (input.type === 'radio') {
      input.dataset.wasChecked = 'true';
    }

    input.classList.remove(...this.uncheckedInputClasses);
    input.classList.add(...this.checkedInputClasses);

    input.labels.forEach((label) => {
      label.classList.remove(...this.uncheckedOptionClasses);
      label.classList.add(...this.checkedOptionClasses);
    });
  }

  uncheck(input) {
    if (input.type === 'radio') {
      input.dataset.wasChecked = 'false';
    }

    input.classList.remove(...this.checkedInputClasses);
    input.classList.add(...this.uncheckedInputClasses);

    input.labels.forEach((label) => {
      label.classList.remove(...this.checkedOptionClasses);
      label.classList.add(...this.uncheckedOptionClasses);
    });
  }
}
