import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['copiedText', 'surveyUrl'];

  static values = {
    activeElementIndex: Number,
  };

  copyText = (event) => {
    if (this.activeElementIndexValue !== null) {
      this.copiedTextTargets[this.activeElementIndexValue].dataset.visible = false;
    }

    const { index } = event.target.dataset;
    navigator.clipboard.writeText(this.surveyUrlTargets[index].innerText);
    this.activeElementIndexValue = index;
    this.copiedTextTargets[index].dataset.visible = true;
  };
}
