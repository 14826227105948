import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { backLink: String };

  navigateBack() {
    const previousUrl = document.referrer;

    if (previousUrl && previousUrl.includes(this.backLinkValue)) {
      window.history.back();
    } else {
      window.location.href = this.backLinkValue;
    }
  }
}
