import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  FILTER_PARAM_PREFIX = 'f_';

  static targets = ['filterWrapper', 'filterValues', 'filterValue', 'saveButton'];

  connect() {
    this.closeAllFilters();
    useClickOutside(this, { element: this.filterWrapperTarget });

    this.saveButtonTargets.forEach((button) => {
      button.addEventListener('click', this.applyFilters, true);
    });
  }

  clickOutside = (event) => {
    if (this.filterValuesTarget.contains(event.target)) return;
    this.closeAllFilters();
  };

  applyFilters = (event) => {
    const url = new URL(window.location.href);

    this.filterValueTargets
      .forEach((checkbox) => {
        const paramName = `${this.FILTER_PARAM_PREFIX}${checkbox.getAttribute('data-filter-attribute')}[]`;
        const paramValue = checkbox.id;
        if (checkbox.checked) {
          if (!url.searchParams.has(paramName, paramValue)) {
            url.searchParams.append(paramName, paramValue);
          }
        } else {
          url.searchParams.delete(paramName, paramValue);
        }
      });

    event.target.setAttribute('href', url.toString());
    window.history.pushState({}, '', url.toString());
  };

  toggleFilterValues = (event) => {
    const open = JSON.parse(event.currentTarget.nextElementSibling.dataset.visible);
    const filterValuesList = event.currentTarget.nextElementSibling;
    this.closeAllFilters();
    filterValuesList.dataset.visible = !open;
  };

  resetFilters = () => {
    const url = new URL(window.location.href);

    this.filterValueTargets
      .forEach((checkbox) => {
        const paramName = `${this.FILTER_PARAM_PREFIX}${checkbox.getAttribute('data-filter-attribute')}[]`;
        const paramValue = checkbox.id;
        if (url.searchParams.has(paramName, paramValue)) {
          url.searchParams.delete(paramName, paramValue);
        }
      });

    url.searchParams.delete(`${this.FILTER_PARAM_PREFIX}topics[]`);
    Turbo.visit(url.toString());
  };

  closeAllFilters = () => {
    this.filterValuesTargets.forEach((filterValues) => {
      // eslint-disable-next-line no-param-reassign
      filterValues.dataset.visible = false;
    });
  };
}
